/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";


.ng-select .ng-select-container {
    border: none !important;
    border-bottom: 1px solid #ccc !important;

}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border: 1px solid #ccc;
    box-shadow: none;
}

.ng-select .ng-select-container .ng-value-container {
    padding-left: 0;
}


.ng-select .ng-arrow-wrapper{
    margin-right: 10px;
}